import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

import ShareButtons from "../components/share/sharebuttons"
import ContactForm from "../components/contact-form/contact-form"

import { FlexWrapper, FlexItem } from "../components/contentwrappers"
import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"

import { kebabCase } from "lodash"

import styled from "styled-components"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div>
      <Layout>
        <SEO title={post.frontmatter.title} />

        <BackgroundImage
          fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
        >
          <Header
            headerText={post.frontmatter.title}
            headerSubText={post.frontmatter.date}
            type="blog"
          ></Header>
        </BackgroundImage>
        <Section>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />

          <div style={{ textAlign: "center" }}>
            <h2>Share the article</h2>

            <ShareButtons
              title={post.frontmatter.title}
              url={url}
            ></ShareButtons>
          </div>

          <h2 style={{ textAlign: "center", marginTop: "1.5em" }}>
            Contact us
          </h2>
          <ContactForm></ContactForm>
          <FlexWrapper>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaBuilding style={{ marginRight: "1em" }} />
                  Address
                </strong>{" "}
                1st Floor, Avenue House, 42-44 Rosemary Street, Belfast, BT1 1QE
              </div>
            </FlexItem>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaEnvelope style={{ marginRight: "1em" }} />
                  Email
                </strong>{" "}
                <a hRef="mailTo:info@watsonlaird.com">info@watsonlaird.com</a>
              </div>
            </FlexItem>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaPhone style={{ marginRight: "1em" }} />
                  Phone
                </strong>{" "}
                028 9066 0719
              </div>
            </FlexItem>
          </FlexWrapper>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 860) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
