import React from "react"

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const ShareButtons = ({ title, url }) => {
  return (
    <div>
      <FacebookShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
      >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>

      <WhatsappShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
      >
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>

      <EmailShareButton url={url} title={title} subject={title}>
        <EmailIcon size={40} round={true} />
      </EmailShareButton>
    </div>
  )
}
export default ShareButtons
