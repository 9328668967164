import React from "react"
import { respondTo } from "../_respondTo"
import styled from "styled-components"
import { palette } from "../_variables"

const Screen = styled.div`
  min-height: 100vh;

  padding: 10vh 5vw;

  ${respondTo.md`
    
  `}
`

const Page = styled.div`
  padding: 10vh 5vw;
  min-height: 100vh;

  ${respondTo.md`
    
  `}
`

const Text = styled.div`
  margin: 30px auto;
  color: white;
  background-color: rgba(0, 42, 108, 0.8);
  padding: 15px;

  border-radius: 0.5em;
  max-width: 960px;

  a {
    color: white;
  }

  hr {
    border-top: 1px solid white;
  }

  font-weight: 300;

  ${respondTo.md`
    text-align: justify;
    padding: 30px;


  `}
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.blogs ? "space-evenly" : "space-evenly")};
  align-items: center;

  flex-wrap: ${props => (props.blogs ? "no-wrap" : "wrap")};

  ${respondTo.md`
  flex-direction: row;
  align-items: stretch;
  `}
`

const FlexI = styled.div`
  margin: 1em;
  //padding: 1em;
  flex: 1;

  ${respondTo.md`
    
  `}
`

const Box = styled.div`
  color: white;
  background-color: ${palette.primary};
  padding: 15px;

  border-radius: 0.5em;

  margin: 20px 0px;
  max-width: ${props => (props.width == "narrow" ? "300px" : "400px")};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;

  ${respondTo.md`
    width: 30vw;
    
    margin: 20px;

  `}
`

const Heading = styled.div`
  color: ${palette.primary};
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
`
const Video = styled.div`
  margin: 30px auto;
  max-width: 960px;
  border-radius: 0.5em;
`

export const ScreenWrapper = ({ id, children }) => {
  return <Screen id={id}>{children}</Screen>
}

export const PageWrapper = ({ children }) => {
  return <Page>{children}</Page>
}

export const TextWrapper = ({ id, children }) => {
  return <Text id={id}>{children}</Text>
}

export const FlexWrapper = ({ children, blogs }) => {
  return <Flex>{children}</Flex>
}

export const FlexItem = ({ flexMe, children }) => {
  return <FlexI flex={flexMe}>{children}</FlexI>
}

export const BoxWrapper = ({ children, width }) => {
  return <Box width={width}>{children}</Box>
}

export const HeadingWrapper = ({ children }) => {
  return <Heading>{children}</Heading>
}

export const VideoWrapper = ({ children }) => {
  return <Video>{children}</Video>
}
